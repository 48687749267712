/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {BaseController} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/controller-factory/BaseController';
import type {ControllerParams} from '@wix/yoshi-flow-editor';
import {IGalleryControllerConfig} from '../../types/galleryTypes';
import {appClient, Scope} from '@wix/app-settings-client';
import {APP_SETTINGS_CDN} from '@wix/wixstores-client-core/dist/es/src/constants';
import _ from 'lodash';
import {WishlistStore} from './WishlistStore';
import {Experiments, WixCustomHeaders} from '../../constants';
import {QueryParamsService} from '@wix/wixstores-client-storefront-sdk';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';

export class WishlistController extends BaseController {
  private wishlistStore: WishlistStore;
  private readonly compId: string;
  private config: IGalleryControllerConfig;
  private readonly queryParamsService: QueryParamsService;
  private rerenderUrlHeaders: {lang: string; currency: string};

  public onConfigUpdate = async (config: IGalleryControllerConfig) => {
    this.config = _.clone(config);
    await this.wishlistStore.updateState(this.config, {APP: {}, COMPONENT: {}});
  };

  /* istanbul ignore next: for PR only, will be tested before merge */
  public onAppSettingsUpdate = (updates: {[key: string]: any}) => {
    if (
      this.siteStore.experiments.enabled(Experiments.EditorGalleryOOI) &&
      updates.scope === Scope.COMPONENT &&
      updates.source === 'app-settings'
    ) {
      void this.wishlistStore.updateState(this.config, {
        APP: undefined,
        COMPONENT: {},
        appSettings: updates.payload,
      });
    }
  };

  constructor(controllerParams: ControllerParams) {
    super(controllerParams);
    this.config = _.clone(controllerParams.controllerConfig.config);
    const isEditor = typeof window !== 'undefined' && window.Wix;

    /* istanbul ignore else: todo(flow-editor): test */
    if (isEditor) {
      this.listenToAppSettingsUpdate();
    }
    this.compId = controllerParams.controllerConfig.compId;
    this.queryParamsService = new QueryParamsService<SiteStore>(this.siteStore);
    this.rerenderUrlHeaders = {
      [WixCustomHeaders.Lang]: this.queryParamsService.getQueryParam(WixCustomHeaders.Lang),
      [WixCustomHeaders.Currency]: this.queryParamsService.getQueryParam(WixCustomHeaders.Currency),
    };
  }

  private listenToAppSettingsUpdate() {
    const appSettingsClient = appClient({scope: Scope.COMPONENT, cdnUrl: APP_SETTINGS_CDN});
    appSettingsClient.onChange((pb) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.wishlistStore.updateState(this.config, {APP: undefined, COMPONENT: {}, appSettings: pb});
    });
  }

  public readonly load = async () => {
    /* istanbul ignore next: cannot test */
    if (this.wishlistStore) {
      const currentLang = this.queryParamsService.getQueryParam(WixCustomHeaders.Lang);
      const currentCurrency = this.queryParamsService.getQueryParam(WixCustomHeaders.Currency);
      const {currency, lang} = this.rerenderUrlHeaders;
      if (currency === currentCurrency && lang === currentLang) {
        return;
      }

      this.rerenderUrlHeaders = {
        [WixCustomHeaders.Lang]: currentLang,
        [WixCustomHeaders.Currency]: currentCurrency,
      };
    }

    this.wishlistStore = new WishlistStore(
      this.config,
      this.setProps.bind(this),
      this.siteStore,
      this.config.externalId,
      this.compId,
      this.config.type,
      this.flowAPI.reportError,
      this.flowAPI.panoramaClient
    );

    return this.wishlistStore.setInitialState().catch(this.flowAPI.reportError);
  };

  public readonly init = async () => {
    await this.load();
  };

  /* istanbul ignore next: no velo functions to test */
  public exports = () => {
    return {
      //
    };
  };

  public getFreeTexts = (): string[] => {
    return [];
  };
}
